import React, { Component } from 'react'
import { IWysiwygdSection } from '~models'
import styles from './styles.module.scss'

export interface WysiwygdSectionProps {
  section: IWysiwygdSection
}

export class WysiwygdSection extends Component<WysiwygdSectionProps> {
  render() {
    const { text } = this.props.section
    return (
      <section className={styles.wrapper}>
        <div className="container" dangerouslySetInnerHTML={{ __html: text }} />
      </section>
    )
  }
}
