import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { observer, inject } from 'mobx-react'
import Img from 'gatsby-image'
import { TextSection } from '~sections'
import { ITextSection, IImage, ISharp, IFluidObject } from '~models'
import { Container } from '~views/shared/components'
import { UIStore } from '~stores/UIStore'
import styles from './styles.module.scss'

export interface GalleryTemplateProps {
  images: IImage[]
  uiStore?: UIStore
}

@inject('uiStore')
@observer
class Template extends Component<GalleryTemplateProps> {
  NR_OF_COLS = 3

  getFluid = (sharp: ISharp): IFluidObject => {
    const { uiStore } = this.props
    const width = uiStore.windowWidth
    if (!width) {
      return sharp.large
    }

    if (width < sharp.small.presentationWidth / this.NR_OF_COLS) {
      return sharp.small
    }
    if (width < sharp.medium.presentationWidth / this.NR_OF_COLS) {
      return sharp.medium
    }
    return sharp.large
  }

  renderImages() {
    const { images } = this.props
    return images.map(image => {
      const fluid = !!image.localFile
        ? this.getFluid(image.localFile.childImageSharp)
        : null
      return fluid ? <Img key={image.id} className={styles.image} fluid={fluid} /> : null
    })
  }

  render() {
    return (
      <React.Fragment>
        <TextSection
          section={
            {
              title: 'Galleri',
            } as ITextSection
          }
        />

        <Container>
          <div className={styles.images_wrapper}>{this.renderImages()}</div>
        </Container>
      </React.Fragment>
    )
  }
}

const query = graphql`
  {
    allWordpressWpMedia(
      filter: {
        title: { ne: "SWACE_PLACEHOLDER" }
        acf: { show_in_gallery: { eq: true } }
      }
    ) {
      pageInfo {
        hasNextPage
      }
      totalCount
      nodes {
        ...imageFields
      }
    }
  }
`

export const GalleryTemplate = () => {
  return (
    <StaticQuery
      query={query}
      render={data => <Template images={data.allWordpressWpMedia.nodes || []} />}
    />
  )
}
