import React, { Component } from 'react'
import styles from './styles.module.scss'
import { ITextSection } from '~models'
import { Link } from 'gatsby'
import { Row, Col } from '~components'

export interface TextSectionProps {
  section: ITextSection
}

export class TextSection extends Component<TextSectionProps> {
  link = () => {
    const {
      linktype,
      link_text,
      file_link,
      internal_link,
      external_link,
    } = this.props.section

    switch (linktype) {
      case 'internal_link':
        return <Link to={`/${internal_link.post_name}`}>{link_text}</Link>

      case 'external_link':
        return (
          <a target="_blank" href={external_link}>
            {link_text}
          </a>
        )

      case 'file_link':
        return (
          <a target="_blank" href={file_link.localFile.url} download>
            {link_text}
          </a>
        )
    }
    return null
  }

  positioning = () => {
    const { title, text, positioning } = this.props.section
    if (positioning === 'row') {
      return (
        <React.Fragment>
          {title && <h1>{title}</h1>}
          {text && <p>{text}</p>}
          {this.link()}
        </React.Fragment>
      )
    }
    return (
      <Row>
        <Col xs={12} sm={12} md={6} lg={5}>
          {title && <h1>{title}</h1>}
        </Col>
        <Col xs={12} sm={12} md={6} lg={7}>
          {text && <p>{text}</p>}
          {this.link()}
        </Col>
      </Row>
    )
  }

  render() {
    const { theme, positioning } = this.props.section
    return (
      <section className={styles[theme]}>
        <div className="container">
          <div className={[styles.wrapper, styles[positioning]].join(' ')}>
            {this.positioning()}
          </div>
        </div>
      </section>
    )
  }
}
