import React from 'react'
import moment from 'moment'
import { Container, Row, Col } from '~components'
import styles from './styles.module.scss'
import { IShowResult, IShowResultEdge } from '~models'
import { StaticQuery, graphql } from 'gatsby'

type yearMapType = { [key: string]: IShowResult[] }

export interface ComponentProps {
  yearMappedResults: yearMapType
}

class Component extends React.Component<ComponentProps> {
  renderResult(showresult: IShowResult) {
    return (
      <Col className={styles.result} key={showresult.id} xs={12} sm={6} md={4}>
        <h5>{showresult.dog_name}</h5>
        <p>Plats: {showresult.location}</p>
        <p>Klass: {showresult.class}</p>
        <p>Domare: {showresult.referee}</p>
        <p>Resultat: {showresult.result}</p>
      </Col>
    )
  }

  renderYear(year: string, showresults: IShowResult[]) {
    return (
      <Row key={year} className={styles.section_wrapper}>
        <Col xs={12} sm={3} className={styles.year_wrapper}>
          <p>{year}</p>
        </Col>
        <Col xs={12} sm={9} className={styles.result_wrapper}>
          <Row>{showresults.map(this.renderResult)}</Row>
        </Col>
      </Row>
    )
  }

  render() {
    const { yearMappedResults } = this.props
    return (
      <section>
        <Container className={styles.wrapper}>
          <h1>Resultat</h1>
          {Object.keys(yearMappedResults)
            .sort()
            .reverse()
            .map(year => this.renderYear(year, yearMappedResults[year]))}
        </Container>
      </section>
    )
  }
}

const mapYear = (showresults: { edges: IShowResultEdge[] }) => {
  if (!showresults) {
    return {}
  }
  return showresults.edges.reduce((map, current) => {
    current.node.acf.id = current.node.id
    current.node.acf.date = moment(current.node.acf.date)
    const year = current.node.acf.date.get('year').toString()
    if (!map[year]) {
      map[year] = []
    }
    map[year].push(current.node.acf)
    return map
  }, {}) as yearMapType
}

const query = graphql`
  {
    allWordpressWpShowResults(filter: { title: { ne: "SWACE_PLACEHOLDER" } }) {
      group(field: acf___type) {
        ...showResultConnectionFields
      }
    }
  }
`

export const ShowResultSection = ({ type }) => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <Component
          yearMappedResults={mapYear(
            data.allWordpressWpShowResults.group.find(g => g.fieldValue === type),
          )}
        />
      )}
    />
  )
}
