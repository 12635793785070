import React, { Component } from 'react'
import { ITemplate, ISections } from '~models'
import { GalleryTemplate } from '../GalleryTemplate'
import { NewsTemplate } from '../NewsTemplate'
import { ResultsTemplate } from '../ResultsTemplate'

export interface TemplateProps {
  template: ITemplate
  sections: ISections
}

export class Template extends Component<TemplateProps> {
  render() {
    const { template } = this.props
    switch (template) {
      case 'gallery':
        return <GalleryTemplate />

      case 'news':
        return <NewsTemplate />

      case 'results':
        return <ResultsTemplate />

      default:
        return null
    }
  }
}
