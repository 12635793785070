import React, { Component } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { NewsBlock } from '~blocks'
import { Button, Container, Row } from '~components'
import { INewsEdge } from 'models'
import styles from './styles.module.scss'

type NewsType = 'News'

interface ListProps {
  type: NewsType
  newsEdges: INewsEdge[]
  listInfo: {
    hasNextPage: boolean
    totalCount: number
  }
  moreUrl?: string
}

class List extends Component<ListProps> {
  render() {
    const { newsEdges, moreUrl, type, listInfo } = this.props
    return (
      <section>
        <Container className={styles.wrapper}>
          <Row>
            {newsEdges &&
              newsEdges.map(edge => (
                <NewsBlock
                  type={type}
                  key={edge.node.id}
                  slug={edge.node.slug}
                  news={edge.node.acf}
                />
              ))}
          </Row>
          {!!moreUrl && listInfo.hasNextPage && (
            <Link to={moreUrl}>
              <Button className={styles.button}>Visa mer</Button>
            </Link>
          )}
        </Container>
      </section>
    )
  }
}

const query = graphql`
  {
    allWordpressWpNews(
      filter: { title: { ne: "SWACE_PLACEHOLDER" } }
      skip: 0
      limit: 9
    ) {
      pageInfo {
        hasNextPage
      }
      totalCount
      edges {
        node {
          ...newsFields
        }
      }
    }
  }
`

interface NewsSectionProps {
  moreUrl?: string
  type: NewsType
}

export const NewsSection = (props: NewsSectionProps) => {
  const { type, moreUrl } = props

  return (
    <StaticQuery
      query={query}
      render={data => (
        <List
          type={type}
          moreUrl={moreUrl}
          newsEdges={data[`allWordpressWp${type}`].edges}
          listInfo={{
            hasNextPage: data[`allWordpressWp${type}`].pageInfo.hasNextPage,
            totalCount: data[`allWordpressWp${type}`].totalCount,
          }}
        />
      )}
    />
  )
}
