import React, { Component } from 'react'
import { NewsSection } from '~sections/listsections'
import { TextSection } from '~sections'
import { ITextSection } from '~models'

export interface NewsTemplateProps {}

export class NewsTemplate extends Component<NewsTemplateProps> {
  render() {
    return (
      <React.Fragment>
        <TextSection
          section={
            {
              title: 'Aktuellt',
            } as ITextSection
          }
        />
        <NewsSection type={'News'} />
      </React.Fragment>
    )
  }
}
