import { graphql } from 'gatsby'

export const showResultFields = graphql`
  fragment showResultFields on wordpress__wp_show_results {
    id
    acf {
      type
      dog_name
      location
      class
      referee
      result
      date
    }
  }
`

export const showResultsConnection = graphql`
  fragment showResultConnectionFields on wordpress__wp_show_resultsGroupConnection {
    fieldValue
    pageInfo {
      hasNextPage
    }
    totalCount
    edges {
      node {
        ...showResultFields
      }
    }
  }
`
