import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import { Col, Div } from '~components'
import styles from './styles.module.scss'
import { INews } from '~models'

type NewsType = 'News'

export interface NewsBlockProps {
  type: NewsType
  news: INews
  slug: string
  highlighted?: boolean
}

export class NewsBlock extends Component<NewsBlockProps> {
  renderImage = () => {
    const { news } = this.props

    return (
      <Div
        className={styles.image}
        square
        background={
          !!news.images ? news.images[0].localFile.childImageSharp.small.src : undefined
        }
      />
    )
  }

  renderStory = () => {
    const { news, slug } = this.props
    const prefix = 'aktuellt'
    return (
      <Link to={`/${prefix}/${slug}`} className={styles.wrapper}>
        {this.renderImage()}
        {<p className={styles.date}>{moment().format('DD MMMM, YYYY')}</p>}
        <h4 className={styles.title}>{news.title}</h4>
      </Link>
    )
  }

  renderQuickInfo = () => {
    const { news } = this.props
    return (
      <a
        className={styles.wrapper}
        href={news.link || ''}
        target={!!news.link ? '_blank' : ''}
      >
        {this.renderImage()}
        <h4 className={styles.title}>{news.title}</h4>
        <p className={styles.text}>{news.text}</p>
      </a>
    )
  }

  content = () => {
    const { news } = this.props
    switch (news.type) {
      case 'quick_info':
        return this.renderQuickInfo()
    }
    return this.renderStory()
  }

  render() {
    const { highlighted } = this.props

    return (
      <Col xs={highlighted ? 12 : 6} md={highlighted ? 6 : 4}>
        {this.content()}
      </Col>
    )
  }
}
