import { Moment } from 'moment'

export enum IShowResultTypes {
  'Vilt',
  'Freestyle',
  'Lydnad',
  'Rallylydnad',
  'Agility',
  'Vallning',
}

export interface IShowResult {
  id: string
  dog_name: string
  location: string
  class: string
  referee: string
  result: string
  date: string | Moment
  type: IShowResultTypes
}

export interface IShowResultEdge {
  node?: {
    id: string
    acf: IShowResult
  }
}
