import React, { Component } from 'react'
import { IYoutubeSection } from '~models'
import Youtube from 'react-youtube'
import styles from './styles.module.scss'

export interface YoutubeSectionProps {
  section: IYoutubeSection
}

export class YoutubeSection extends Component<YoutubeSectionProps> {
  opts = {
    playerVars: {
      autoplay: 0,
      controls: 0,
      showinfo: 0,
      rel: 0,
      autohide: 1,
    },
  }

  getYoutubeID = url => {
    var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
    var match = url.match(regExp)
    return match && match[1].length == 11 ? match[1] : false
  }

  render() {
    const { title, url } = this.props.section

    return (
      <section className={['container', styles.wrapper].join(' ')}>
        {title && <h2>{title}</h2>}
        <div className={styles.youtube_wrapper}>
          <Youtube
            containerClassName="youtube"
            videoId={this.getYoutubeID(url)}
            opts={this.opts}
          />
        </div>
      </section>
    )
  }
}
