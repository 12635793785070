import { graphql } from 'gatsby'

export const titleTextSectionFields = graphql`
  fragment titleTextSectionFields on WordPressAcf_title_text {
    id
    title
    text
    theme
    positioning
    linktype
    link_text
    file_link {
      localFile {
        url
      }
    }
    internal_link {
      post_name
    }
    external_link
  }
`

export const youtubeSectionFields = graphql`
  fragment youtubeSectionFields on WordPressAcf_youtube {
    id
    title
    url
  }
`

export const wysiwygdSectionFields = graphql`
  fragment wysiwygdSectionFields on WordPressAcf_wysiwygd {
    id
    text
  }
`

export const imageSectionFields = graphql`
  fragment imageSectionFields on WordPressAcf_images {
    id
    horisontal
    images {
      ...imageFields
    }
  }
`

export const listSectionFields = graphql`
  fragment listSectionFields on WordPressAcf_list {
    id
    type
  }
`

export const pageFields = graphql`
  fragment pageFields on Node {
    __typename
    ... on WordPressAcf_title_text {
      ...titleTextSectionFields
    }
    ... on WordPressAcf_images {
      ...imageSectionFields
    }
    ... on WordPressAcf_list {
      ...listSectionFields
    }
    ... on WordPressAcf_wysiwygd {
      ...wysiwygdSectionFields
    }
    ... on WordPressAcf_youtube {
      ...youtubeSectionFields
    }
  }
`
