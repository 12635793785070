import React, { Component } from 'react'
import { ShowResultSection } from '~sections'
import { IShowResultTypes } from '~models'

export interface ResultsTemplateProps {}

export class ResultsTemplate extends Component<ResultsTemplateProps> {
  render() {
    const tabitems = Object.keys(IShowResultTypes)
      .filter(type => isNaN(parseInt(type)))
      .map(type => ({
        title: type,
        item: <ShowResultSection type={type} />,
      }))

    return null
  }
}
