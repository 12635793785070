import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { IImageSection, ISharp } from '~models'
import { UIStore } from '~stores/UIStore'
import styles from './styles.module.scss'

export interface ImageSectionProps {
  section: IImageSection
  uiStore?: UIStore
}

@inject('uiStore')
@observer
export class ImageSection extends Component<ImageSectionProps> {
  render() {
    const { section, uiStore } = this.props

    const count = section.images.length

    const imgSrc = (sharp: ISharp): string => {
      const width = uiStore.windowWidth
      if (!width) {
        return sharp.large.src
      }

      if (width < sharp.small.presentationWidth) {
        return sharp.small.src
      }
      if (width < sharp.medium.presentationWidth) {
        return sharp.medium.src
      }
      return sharp.large.src
    }

    const imgClassName = (index): string => {
      if (section.horisontal || (index % 2 === 0 && index === count - 1)) {
        return ''
      }
      return 'half'
    }

    return (
      <section
        className={[
          styles.wrapper,
          section.horisontal && styles.horisontal,
          !section.horisontal && 'container',
        ].join(' ')}
      >
        {section.images.map((image, index) => (
          <img
            key={index.toString()}
            className={imgClassName(index)}
            src={image.localFile ? imgSrc(image.localFile.childImageSharp) : null}
          />
        ))}
      </section>
    )
  }
}
