import { graphql } from 'gatsby'

export const newsAcfFields = graphql`
  fragment newsFields on wordpress__wp_news {
    id
    slug
    date
    acf {
      title
      text
      type
      link
      images {
        ...imageFields
      }
    }
  }
`
