import React, { Component } from 'react'
import { IImage } from '~models'
import styles from './styles.module.scss'

export interface ImagesBlockProps {
  images: IImage[]
}

export class ImagesBlock extends Component<ImagesBlockProps> {
  render() {
    const { images } = this.props
    return (
      <div className={styles.wrapper}>
        {images.map(image => (
          <div key={image.id} className={styles.image_wrapper}>
            <img
              src={
                image.localFile.childImageSharp
                  ? image.localFile.childImageSharp.large.src
                  : ''
              }
            />
            <p>{image.alt_text}</p>
          </div>
        ))}
      </div>
    )
  }
}
