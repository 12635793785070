import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Sections, Header, Footer } from '~views'
import { ISections, ITemplate } from '~models'
import { withStore } from '~libs/mobx'
import '~libs/graphql'
import { Template } from '~views/wp_templates/Template'

interface PageTemplateProps {
  data: {
    wordpressPage: {
      id: number
      acf: {
        template: ITemplate
        sections_page: ISections
      }
    }
  }
}

class PageTemplate extends Component<PageTemplateProps> {
  template = () => {
    const { template, sections_page } = this.props.data.wordpressPage.acf

    if (template === 'page_builder') {
      return <Sections sections={sections_page} />
    }

    return <Template template={template} sections={sections_page} />
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        {this.template()}
        <Footer />
      </React.Fragment>
    )
  }
}

export default withStore(PageTemplate)

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      acf {
        template
        sections_page {
          ...pageFields
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
