import React, { Component } from 'react'
import { IListSection } from '~models'
import { NewsSection } from '../listsections'

export interface ListSectionProps {
  section: IListSection
}

export class ListSection extends Component<ListSectionProps> {
  render() {
    const { section } = this.props
    switch (section.type) {
      case 'news':
        return <NewsSection moreUrl={'/aktuellt'} type={'News'} />
    }

    return null
  }
}
