import React, { Component } from 'react'
import { ISections } from '~models'
import {
  TextSection,
  ImageSection,
  ListSection,
  WysiwygdSection,
  YoutubeSection,
} from '~sections'

export interface SectionsProps {
  sections: ISections
}

export class Sections extends Component<SectionsProps> {
  render() {
    const { sections } = this.props
    const renderedSections = (sections || []).map((section, i) => {
      switch (section.__typename) {
        case 'WordPressAcf_title_text':
          return <TextSection key={section.id} section={section} />
        case 'WordPressAcf_images':
          return <ImageSection key={section.id} section={section} />
        case 'WordPressAcf_list':
          return <ListSection key={section.id} section={section} />
        case 'WordPressAcf_wysiwygd':
          return <WysiwygdSection key={section.id} section={section} />
        case 'WordPressAcf_youtube':
          return <YoutubeSection key={section.id} section={section} />
      }

      return <div key={i.toString()} />
    })

    return renderedSections
  }
}
